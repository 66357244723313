import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'I have been doing DevOps since 2014, that was when Docker was in its infant stages and AWS Cloud has began to pick up in the industry. Here I am today, continuing my journey writing IaC, and working with CI/CD Pipelines.',
  paragraphTwo:
    'With a background in System Administration and Network Engineering, I have taken my skills to the next level in DevOps after also picking up Ruby On Rails as my first programming language on the job.',
  paragraphThree:
    'I have helped various companies of all sizes meet thier DevOps needs, from Migration to setting up pipelines and also help integrate into the cloud.',
  // resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'AWS and GCP',
    info: 'I have worked with both AWS and GCP Cloud for various clients. I have done on-prem to cloud migrations and hybrid cloud migrations.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Docker, Kubernetes and Serverless',
    info: 'I have worked in EKS(AWS) and GKE(GCP) setting up and deploy pods and deployments in K8s',
    info2:
      'On the networking side of things, I have help setup and maintained Ingress Networking and Load Balancing docker applications',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'CI/CD Pipelines and Workflows + GitOps',
    info: 'Jenkins, ArgoCD, Tekton, Gitlab Pipelines, and Github Actions',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Monitoring, Metrics and Alerts',
    info: 'Grafana, Prometheus, Datadog and the ELK Stack',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'recruit@dngo.me',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //  id: nanoid(),
    //  name: 'twitter',
    //  url: '',
    // },
    // {
    //  id: nanoid(),
    //  name: 'codepen',
    //  url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/dngome',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/eyengo',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
